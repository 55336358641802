import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonComp from '../../../common/ButtonComp';
import moment from 'moment';
import 'moment/locale/it';
import { uris, fetchParam } from '../../../../uris';

import * as actions from './action/datiQuestionario';
import * as actionType from './actionType/datiQuestionario';

import DomandeQuestionario from './DomandeQuestionario';

import { COD_QUESTIONARIO_ANAMNESTICO, COD_BLOCCO_QUESTIONARIO_100, COD_BLOCCO_QUESTIONARIO_101, 
         COD_BLOCCO_QUESTIONARIO_102, COD_BLOCCO_QUESTIONARIO_103, COD_BLOCCO_QUESTIONARIO_104, COD_BLOCCO_QUESTIONARIO_113, 
         COD_BLOCCO_QUESTIONARIO_212, COD_BLOCCO_QUESTIONARIO_307, QUESTIONARIO_ANAMNESTICO, QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE} from '../../../../utility/jsConstants';
import { checkEtaMassima } from '../../../../utility/genericUtility';
import { getQuestionarioAnamnestico } from '../../../../utility/questionarioUtility';

const styles = theme => ({
  titolo: {
	textAlign: 'center',	  
	marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: theme.button,
  textErrorMessage: theme.textErrorMessage,
  sezioneOblio: {
	width:'100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  sezioneAss: {
	width:'100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },  
  sezioneDotted: {
	width:'100%',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
  },
  sezioneDotted_2: {
	width:'100%',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },

  colMarine: {
	  color: theme.marine,
  }
});

const mapStateToProps = state => {
  //console.log('#RTCFA DatiQuestionarioAnamnestico.mapStateToProps.state', state);
  return {
    datiQuestionarioAnamnestico: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnestico,
    datiQuestionarioAnamnesticoMalattiaGrave: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoMalattiaGrave,
    datiQuestionarioAnamnesticoSemplificato: state.areaDatiQuestionarioReducer.questionario.datiQuestionarioAnamnesticoSemplificato,
    prodotto: state.areaProdottoReducer.prodotto,
    datiPremioCapitaleAssicurato: state.areaDatiPremioCapitaleAssicuratoReducer.caratteristicheContratto.datiPremioCapitaleAssicurato,
    assicurato: state.areaAssicuratoReducer.assicuratoFisico,
    isChangedFromSemplificato: state.areaDatiQuestionarioReducer.questionario.isChangedFromSemplificato,
    dataNascitaContrFis: state.areaContraenteReducer.contraenteFisico.datiGenerali.dataNascita
  };
};
	
const mapDispatchToProps = dispatch => {
  //console.log('#RTCFA DatiModalitaPagamento.mapDispatchToProps.dispatch', dispatch);
  return {
    updateQuestionario: (unDato, tipoQuestionario, unActionType) => dispatch(actions.updateQuestionario(unDato, tipoQuestionario, unActionType)),	  
    salvaEsitoDatiQuestionario: (esito, esitoMessaggio, tipoQuestionario) => dispatch(actions.salvaEsitoDatiQuestionario(esito, esitoMessaggio, tipoQuestionario)),
    updateIsChangedFromSemplificato: (unDato, unActionType) => dispatch(actions.updateIsChangedFromSemplificato(unDato, unActionType)),
    updateTipoCurrentQuestionario: (unDato, unActionType) => dispatch(actions.updateTipoCurrentQuestionario(unDato, unActionType)),
//    updateShowSemplificato: (showSemplificato, showOnlySemplificato, unActionType) => dispatch(actions.updateShowSemplificato(showSemplificato, showOnlySemplificato, unActionType))
  };
};

class DatiQuestionarioSanitario extends React.Component {
  constructor(props) {
  super(props);
  // dataNascitaMassima è la data in cui il soggetto sarebbe nato se compiesse al giorno corrente 55 anni e 6 mesi.
  // Si controlla che la data di nascita effettiva sia dopo quel giorno (isAfter)
  // e che quindi abbia meno di 55 anni (approssimato al giorno). 
  // Si controlla inoltre che il capitale assicurato sia inferiore ai 200.000€
  // e che non si sia passati dal questionario semplificato a quello completo (almeno una risposta == 'si')
  //  datRif,     dataNascita                             anniMassimi, mesiMassimi
  const dataNascita = (this.props.assicurato.flAssicuratoUgualeContraente === 'S') ? this.props.dataNascitaContrFis : this.props.assicurato.datiGenerali.dataNascita;
  const datiPremioCapitaleAssicurato = this.props.datiPremioCapitaleAssicurato;
  const datiQuestionarioAnamnestico = this.props.datiQuestionarioAnamnestico;
  const datiQuestionarioAnamnesticoMalattiaGrave = this.props.datiQuestionarioAnamnesticoMalattiaGrave;
  const datiQuestionarioAnamnesticoSemplificato = this.props.datiQuestionarioAnamnesticoSemplificato;
// 2020-02-08 PFD - START 
//  const isQuestionarioSemplificato = checkEtaMassima(moment(), dataNascita, 55, 6)
//                                  && this.props.datiPremioCapitaleAssicurato.capitaleAssicurato
//                                  && this.props.datiPremioCapitaleAssicurato.capitaleAssicurato <= 200000
//                                  && !this.props.isChangedFromSemplificato 
//                                  && !datiQuestionarioAnamnestico.datiQuestionarioValida
//                                  && !datiQuestionarioAnamnesticoMalattiaGrave.datiQuestionarioValida;
//  const malattiaGrave = this.props.datiPremioCapitaleAssicurato.malattiaGrave;
//  const isMalattiaGrave = (malattiaGrave === true);
//  //Si da la precedenza al questionatio per Malattia grave. Nel caso in cui non sia tale, si verifica che possa essere
//  //         semplificato, altrimenti si sottopone il questionario Anamnestico
//  const questionarioAnam = !isMalattiaGrave ? 
//                          (isQuestionarioSemplificato ? datiQuestionarioAnamnesticoSemplificato.questionarioAnamnesticoSemplificato
//                          : datiQuestionarioAnamnestico.questionarioAnamnestico)
//                          : datiQuestionarioAnamnesticoMalattiaGrave.questionarioAnamnesticoMalattiaGrave;
//  const tipoQuestionarioAnam = !isMalattiaGrave ? 
//                              (isQuestionarioSemplificato ? QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO : QUESTIONARIO_ANAMNESTICO) 
//                              : QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE;
  
//  if(tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_SEMPLIFICATO) {
//	  this.props.updateShowSemplificato(true, true, actionType.UPDATE_SHOW_SEMPLIFICATO)
//  }


  const questionarioAnamnestico = getQuestionarioAnamnestico (dataNascita, datiPremioCapitaleAssicurato, datiQuestionarioAnamnestico, datiQuestionarioAnamnesticoSemplificato, datiQuestionarioAnamnesticoMalattiaGrave);
  const tipoQuestionarioAnam = questionarioAnamnestico.tipoQuestionario;
  
  let questionarioAnam = null;  
  if (tipoQuestionarioAnam === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {  
	 questionarioAnam = datiQuestionarioAnamnesticoMalattiaGrave.questionarioAnamnesticoMalattiaGrave;	 
  }else {
	  // Questionario Anamnestico Standard
	 questionarioAnam = datiQuestionarioAnamnestico.questionarioAnamnestico;
  }
//2020-02-08 PFD - END
  
  console.log('#QUEST constructor this.props.datiPremioCapitaleAssicurato.malattiaGrave', this.props.datiPremioCapitaleAssicurato.malattiaGrave);
	console.log('#QUEST constructor tipoQuestionarioAnam', tipoQuestionarioAnam);	
    this.state = {
      questionarioAnamnestico: questionarioAnam,
      tipoQuestionarioAnamnestico: tipoQuestionarioAnam,
      esito: '',
      esitoMessaggio: '',
      counterRisposteSi: 0
	  };
  };

  handleChangeConfermaDatiQuestionario = event => {
    this.props.updateQuestionario(true, this.state.tipoQuestionarioAnamnestico, actionType.SALVA_DATI_QUESTIONARIO);    
    const questionario = (this.state.tipoQuestionarioAnamnestico !== QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE)
                        ? this.props.datiQuestionarioAnamnestico
                        : this.props.datiQuestionarioAnamnesticoMalattiaGrave;
    const codProdotto = this.props.prodotto.codice;
    const dataOggi = moment().format('DD/MM/YYYY');
    const tariffa = (this.props.datiPremioCapitaleAssicurato.tariffa !== '') ? this.props.datiPremioCapitaleAssicurato.tariffa : '';
    const testataQuestionario = {
	  codSocieta: '143',
	  codiceProdotto: codProdotto,
	  tariffa: tariffa,
	  codiceQuestionario: COD_QUESTIONARIO_ANAMNESTICO,
	  dataProposta: dataOggi,
  };
    questionario.testataQuestionario = testataQuestionario;
    
    let opts = null;
    if(this.state.tipoQuestionarioAnamnestico === QUESTIONARIO_ANAMNESTICO) {
      opts = {
        testataQuestionario: questionario.testataQuestionario,
        questionarioAnamnestico: questionario.questionarioAnamnestico,
      };
    } else if(this.state.tipoQuestionarioAnamnestico === QUESTIONARIO_ANAMNESTICO_MALATTIA_GRAVE) {
      opts = {
        testataQuestionario: questionario.testataQuestionario,
        questionarioAnamnesticoMalattiaGrave: questionario.questionarioAnamnesticoMalattiaGrave,
      };
    } 
    fetch(uris.controllaAnamnestico, fetchParam(opts))
    .then (
      response => response.json(),
      error => console.error('An error occurred.', error),
    )
    .then((result) => {
    // If request is good update state with fetched data	
      const esito = result[0].label;  
      const valore = result[0].value;
      let esitoMessaggio = '';
      if(COD_BLOCCO_QUESTIONARIO_100 === valore) {
        esitoMessaggio = '100';
      } else if(COD_BLOCCO_QUESTIONARIO_101 === valore) {
        esitoMessaggio = '101';
      } else if(COD_BLOCCO_QUESTIONARIO_102 === valore) {
        esitoMessaggio = '102';
      } else if(COD_BLOCCO_QUESTIONARIO_103 === valore) {
        esitoMessaggio = '103';
      } else if(COD_BLOCCO_QUESTIONARIO_104 === valore) {
        esitoMessaggio = '104';
      } else if(COD_BLOCCO_QUESTIONARIO_113 === valore) {
        esitoMessaggio = '113';
      } else if(COD_BLOCCO_QUESTIONARIO_212 === valore) {
        esitoMessaggio = '212';
      } else if(COD_BLOCCO_QUESTIONARIO_307 === valore) {
        esitoMessaggio = '307';
      }
      this.setState({
        esito: esito,
        esitoMessaggio: esitoMessaggio
      });
  	  console.log('#QUEST handleChangeConfermaDatiQuestionario tipoQuestionario', this.state.tipoQuestionarioAnamnestico);
      this.props.salvaEsitoDatiQuestionario(esito, esitoMessaggio, this.state.tipoQuestionarioAnamnestico, actionType.SALVA_ESITO_DATI_QUESTIONARIO);
      
    //2020-02-08 PFD - START
        //2019-09-05 PFD: Tolto esitoMessaggio!=='101', vale a dire che il Questionario diventa 'verde' anche se NON idoneo      
//      this.props.updateQuestionario((esito==='OK') || (esitoMessaggio!=='100' && esitoMessaggio!=='307'), this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
//      if(esito === 'OK') {
//        this.props.updateTipoCurrentQuestionario(this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_TIPO_CURRENT_QUESTIONARIO);
//        this.props.updateShowSemplificato(false, false, actionType.UPDATE_SHOW_SEMPLIFICATO)
//      }

      const isQuestionarioOk = (esito==='OK') || (esitoMessaggio!=='100' && esitoMessaggio!=='307');
      this.props.updateQuestionario(isQuestionarioOk, this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_DATI_QUESTIONARIO_VALIDA);
      if (isQuestionarioOk) {
    	  this.props.updateTipoCurrentQuestionario(this.state.tipoQuestionarioAnamnestico, actionType.UPDATE_TIPO_CURRENT_QUESTIONARIO);
      }
    //2020-02-08 PFD - END  
    });
  } 
  
  render() {
    const { classes } = this.props;   
    const { t } = this.props; //hoc
    return (
	  <div>
		<div className={classes.sezioneOblio}>
			<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_1')}</Typography>
	    </div>
	    <div className={classes.sezioneAss}>
	    	<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_2')}</Typography>
	    </div>
	    <div className={classes.sezioneDotted}>
	    	<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_3')}</Typography>
	    </div>
	    <div className={classes.sezioneDotted_2}>
			<Typography variant="caption" className={classes.colMarine}>{t('dirittoObblioOncologico_4')}</Typography>
		</div>
    
        {this.state.questionarioAnamnestico.map((elemento, index) => (	
          index === 0 ?
            <div key={index} className={classes.titolo}>
              <Typography variant="h6">{elemento.sezione.testo}</Typography>
            </div>
			:
			<DomandeQuestionario key={index} domande={elemento.domande} titoloSezione={elemento.sezione.testo} tipoQuestionario={this.state.tipoQuestionarioAnamnestico} /> 
        ))}
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
	        <Grid container direction="row" justify="center" alignItems="center">	
	          <Grid item>
	            {this.state.esito==='OK' &&
	      	      <Typography className={classes.textErrorMessage}>{t('questionaIdoneo')}</Typography>
	            }
	            {this.state.esito==='KO' &&
	      	      <Typography className={classes.textErrorMessage}>{t(this.state.esitoMessaggio)}</Typography>
	            }
	          </Grid>
	        </Grid>
	      </Grid>  
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>	
              <Grid item>
                <Grid item>
                  <ButtonComp
                    aClassName={classes.button}
                    buttonLabel={'conferma'}
                    onClick={this.handleChangeConfermaDatiQuestionario}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
	    </Grid>
	  </div>
    );
  }
}

DatiQuestionarioSanitario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(DatiQuestionarioSanitario)));
